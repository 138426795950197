
// Default .button class
.button {
    @extend %button;

    &:hover {
        @extend %hover;
    }

    a {
        color: inherit;
        transition: inherit;
    }
}

.morelink {
    @extend %read-more;
}
