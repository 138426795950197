
//==========  List styles  ==========//

// list item styles
%default-list {
    padding: 0 0 0 $block-padding;
    margin: 0 0 $paragraph-margin;
}

%default-ul {
    list-style: disc;
    @extend %default-list;
		 li {
			 margin-bottom: $block-margin / 4;
		 }
}

%default-ol {
    list-style: decimal;
    @extend %default-list;
		 li {
			 margin-bottom: $block-margin / 4;
		 }
}

// list item reset
%reset-ul {
    list-style: none;
    padding: 0;
    margin: 0;
}


//==========  Buttons and default styles  ==========//

// base button styles
// extend for different button styles
%button {
    display: inline-block;
    border: none;
    @include if( $border-radius, ( border-radius: $border-radius ) );
    @include uppercase($h6 - .3);
    background: $color-primary;
    color: $color-white;
    padding: ($block-padding / 1.5) ($block-padding);
    text-align: center;
    transition: $transition;
}

%hover {
    background: $color-secondary-light;
    color: $color-white;
}

// extend for default .read-more
%read-more {
    @extend %button;

    &:hover {
        @extend %hover;
    }
}

