
// Clear paragraph styles
p {
	margin: 0;
}

// Reset strong tags
strong {
	font-weight: $font-bold;
}

// Reset hr tags
hr {
	border: $border;
}

blockquote {
	margin: 0;
}

.block-text {

	blockquote {
		border-left: 8px solid rgba($color-secondary, .15);
		font-style: italic;
		margin-bottom: $block-margin;
		padding: $site-padding 0 $site-padding ($site-padding * 1.5);

		p {
			margin: 0;
		}
	}
}
